import { LegendProps as NivoLegendProps } from '@nivo/legends';
import React from 'react';

export type {
  PointTooltip as NivoPointTooltip,
  Layer as NivoLayer,
  CustomLayer as NivoCustomLayer,
} from '@nivo/line';

export { TooltipWrapper as NivoTooltipWrapper } from '@nivo/tooltip';

export type PointDataType = number | string | Date | null;

export enum AxisDataType {
  NUMBER = 'linear',
  DATE = 'time',
}

export type TimePrecision = 'minute' | 'hour' | 'day' | 'month' | 'year';

// XAxisScale
// provide either numTicks or tickValues, not both
interface TickValuesXScale {
  numTicks?: never;
  tickValues: PointDataType[];
}
interface NumTicksXScale {
  numTicks: number;
  tickValues?: never;
}
type XAxisTicks = TickValuesXScale | NumTicksXScale;
// only AxisDataType.DATE will have precision
type NumberXScale = XAxisTicks & {
  type: AxisDataType.NUMBER;
  precision?: never;
};
type DateXScale = XAxisTicks & {
  type: AxisDataType.DATE;
  precision: TimePrecision;
};
export type XAxisScale = NumberXScale | DateXScale;

export type YAxisScale = {
  type: AxisDataType.NUMBER;
  numTicks: number;
};

export interface Point {
  x: PointDataType;
  y: PointDataType;
}

export type MouseHandler = (
  point: { serieId: string; point: Point },
  event: React.MouseEvent
) => void;

export type LegendProps = {
  anchor: string;
  direction: string;
  justify: boolean;
  translateX: number;
  translateY: number;
  itemWidth: number;
  itemHeight: number;
  itemsSpacing: number;
  symbolSize: number;
  symbolShape: NivoLegendProps['symbolShape'];
  itemDirection: string;
  itemTextColor: string;
};
