import { Bar as NivoBar, BarSvgProps as NivoBarProps } from '@nivo/bar';
import { ScaleBandSpec, ScaleValue, TicksSpec } from '@nivo/scales';
import useResizeObserver from '@react-hook/resize-observer';
import React, { useRef, useState } from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

import { BarDatum } from './types';
import { calculateMargins, formatValue as defaultValueFormat, getLegend } from './utils';

export interface BarChartProps extends Omit<NivoBarProps<BarDatum>, 'height' | 'width'> {
  css?: PicnicCss;
  keys: string[];
  indexBy: string;
  labelBy?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  enableLegend?: boolean;
  enableGridY?: boolean;
  yTicks?: Array<TicksSpec<ScaleValue>>;
  tickMultiplesToShow?: number; // will show every nth tick. Useful for many bars in a small area.
}

// manually export this type as it's not exported by Nivo
export type BarAxisProps = BarChartProps['axisBottom'];

export const BarChart: React.FC<BarChartProps> = ({
  css,
  data,
  keys,
  indexBy,
  enableLabel = false,
  labelBy = '',
  xAxisLabel = '',
  yAxisLabel = '',
  enableLegend = false,
  enableGridY = false,
  yTicks,
  valueFormat = defaultValueFormat,
  tickMultiplesToShow = 1,
  onMouseEnter,
  ...props
}) => {
  const [responsiveWidth, setResponsiveWidth] = useState(100);
  const [responsiveHeight, setResponsiveHeight] = useState(100);
  const parentRef = useRef<HTMLDivElement>(null);

  useResizeObserver(parentRef, (entry) => {
    if (entry.target !== null) {
      const { clientWidth, clientHeight } = entry.target;
      setResponsiveWidth(clientWidth);
      setResponsiveHeight(clientHeight);
    }
  });

  const margin = calculateMargins(
    xAxisLabel.length > 0,
    yAxisLabel.length > 0,
    enableLegend,
    enableGridY,
    keys
  );
  const legend = enableLegend ? getLegend() : undefined;
  const tickValues = yTicks || data.map((d) => d[indexBy]);
  const tickLabels = labelBy ? data.map((d) => d[labelBy]) : tickValues;
  const indexScale: ScaleBandSpec = { type: 'band', round: true };

  const ticksToShow = tickValues.filter((_, i) => i % tickMultiplesToShow === 0);
  const axisBottom: BarAxisProps = {
    legend: xAxisLabel,
    legendOffset: 40,
    legendPosition: 'middle',
    tickValues,
    format: (tick) => {
      if (!ticksToShow.includes(tick)) return '';

      const index = tickValues.indexOf(tick);
      return tickLabels[index];
    },
  };

  const axisLeft: BarAxisProps = {
    legend: yAxisLabel,
    legendOffset: -60,
    legendPosition: 'middle',
    format: valueFormat,
  };

  return (
    <Box ref={parentRef} css={css}>
      <NivoBar
        width={responsiveWidth}
        height={responsiveHeight}
        data={data}
        keys={keys}
        indexBy={indexBy}
        valueFormat={valueFormat}
        enableLabel={enableLabel}
        indexScale={indexScale}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        margin={margin}
        labelSkipHeight={10}
        labelTextColor={{ from: 'color', modifiers: [['darker', 2.0]] }}
        colors={{ scheme: 'set3' }}
        legends={legend}
        theme={{
          textColor: '#656567', // $textSubdued
          fontFamily: 'Ginto Normal',
        }}
        onMouseEnter={onMouseEnter}
        {...props}
      />
    </Box>
  );
};
