import { Point, Serie } from '@nivo/line';
import { format, isValid as isValidDate } from 'date-fns';
import max from 'lodash/max';
import maxBy from 'lodash/maxBy';
import mean from 'lodash/mean';
import min from 'lodash/min';
import round from 'lodash/round';

import { PointDataType, TimePrecision } from './types';

const ONE_THOUSAND = 1000;
const ONE_MILLION = 1000000;
const ONE_BILLION = 1000000000;

const precisionToXAxisDateFormat: Record<TimePrecision, string> = {
  year: 'MMM y',
  month: 'MMM',
  day: 'MMM d',
  hour: 'eee hha',
  minute: 'hh:mma MM/d',
};

export const DATE_DISPLAY_FORMAT = '%Y-%m-%d';

export const handleXAxisFormat =
  (precision?: TimePrecision) =>
  (val: PointDataType): string => {
    if (val === null) {
      return '';
    }

    if (!precision || !isValidDate(new Date(val))) {
      return `${val}`;
    }

    return format(new Date(val), precisionToXAxisDateFormat[precision]);
  };

export const handleYAxisFormat = (val: PointDataType): string => {
  if (typeof val === 'number') {
    if (val >= ONE_BILLION) {
      return `${round(val / ONE_BILLION, 1)}b`;
    }
    if (val >= ONE_MILLION) {
      return `${round(val / ONE_MILLION, 1)}m`;
    }
    if (val >= ONE_THOUSAND) {
      return `${round(val / ONE_THOUSAND, 1)}k`;
    }
  }
  return `${val}`;
};

const PU_PX_PER_CHARACTER = 6;
const PU_MIN_X_MARGIN = 14;
const PU_TOP_MARGIN = 8;
const PU_BOTTOM_MARGIN = 32;
const PU_TOP_MARGIN_LEGEND = 26;
const PU_BOTTOM_MARGIN_LEGEND = 52;

export const calculateMargin = (
  yAxisTicks: number[],
  enableLegend: boolean
): { top: number; right: number; bottom: number; left: number } => {
  let leftPx = PU_MIN_X_MARGIN;
  if (yAxisTicks.length > 0) {
    const longestNumber =
      maxBy(yAxisTicks, (val) => {
        return handleYAxisFormat(val).length;
      }) || 0;
    // count the number of characters displayed in y-axis, but ignore the decimal points
    const longestNumberLength = handleYAxisFormat(longestNumber).length;
    leftPx = longestNumberLength * PU_PX_PER_CHARACTER + PU_MIN_X_MARGIN;
  }

  return {
    top: enableLegend ? PU_TOP_MARGIN_LEGEND : PU_TOP_MARGIN,
    right: PU_MIN_X_MARGIN,
    bottom: enableLegend ? PU_BOTTOM_MARGIN_LEGEND : PU_BOTTOM_MARGIN,
    left: leftPx,
  };
};

export const calculateMaxYScale = ({
  numTicks,
  yValues,
  isYAxisStartAtZero,
}: {
  yValues: number[][];
  numTicks: number;
  isYAxisStartAtZero: boolean;
}) => {
  // find mean value among all lines
  const meanYValue = Math.ceil(
    mean(
      yValues.map((ySerie) => {
        const meanInSerie = mean(ySerie);
        return meanInSerie || 0;
      })
    )
  );

  if (meanYValue === 0) {
    return 100;
  }

  const maxValue = Math.ceil(
    max(
      yValues.map((ySerie) => {
        const maxInSerie = max(ySerie);
        return maxInSerie || 0;
      })
    ) as number
  );

  // either set the max y scale to the mean*2 so the lines sit in the middle OR set it to the max value
  let maxYScale = isYAxisStartAtZero ? Math.max(meanYValue * 2, maxValue) : maxValue;
  const digits = maxYScale.toString().length;

  // convert the max scale to a 2 digit number. ex: 12,567 => 12.5
  maxYScale = maxYScale / Math.pow(10, digits - 2);
  // if round up to the nearest multiple of NUM_TICKS. ex: 12.5 => 15, NUM_TICKS==5
  maxYScale = Math.ceil(maxYScale / numTicks) * numTicks;
  // scale the number back up. ex: 15 => 15,000
  maxYScale = maxYScale * Math.pow(10, digits - 2);

  return maxYScale;
};

export const calculateMinYScale = ({
  yValues,
  isYAxisStartAtZero,
}: {
  yValues: number[][];
  isYAxisStartAtZero: boolean;
}) => {
  if (isYAxisStartAtZero) {
    return 0;
  }

  const minValue = Math.floor(
    min(
      yValues.map((ySerie) => {
        const minInSerie = min(ySerie);
        return minInSerie || 0;
      })
    ) as number
  );

  const maxValue = Math.ceil(
    max(
      yValues.map((ySerie) => {
        const maxInSerie = max(ySerie);
        return maxInSerie || 0;
      })
    ) as number
  );

  const tickRange = maxValue - minValue;

  let minYScale = minValue;
  const digits = Math.round(minYScale).toString().length;
  // convert the max scale to a 2 digit number. ex: 12,567 => 12.5
  minYScale = minYScale / Math.pow(10, digits - 2);
  // round down 12.5 => 12
  minYScale = Math.floor(minYScale);
  // scale the number back up. ex: 12 => 12,000
  minYScale = minYScale * Math.pow(10, digits - 2);

  // if the minYScale is relatively close to zero, use zero
  minYScale = minYScale < tickRange ? 0 : minYScale;
  return minYScale;
};

export const calculateGridYValues = ({
  minYScale,
  maxYScale,
  numTicks,
}: {
  minYScale: number;
  maxYScale: number;
  numTicks: number;
}) => {
  return [...Array(numTicks).keys()].map((v) => {
    const range = maxYScale - minYScale;
    const val = minYScale + v * (range / (numTicks - 1));
    return val;
  });
};

export const calculateAnchor = ({
  data,
  series,
}: {
  data: Serie[];
  series: Array<{
    index: number;
    serieId: string | number;
    color: string;
    point: Point['data'];
  }>;
}) => {
  if (!data.length || !series.length) return 'top';

  const numElementsX = data[0].data.length; // length is same for all datums
  const serieIndex = series[0].index; // first serie starts at 0
  const isFirstHalf = serieIndex < numElementsX / 2;
  const anchor = isFirstHalf ? 'right' : 'left';

  return anchor;
};
