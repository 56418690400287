import React, { ReactNode, useMemo } from 'react';

import { Box, Card, Heading, Text } from '@attentive/picnic';

import { Point } from '../types';

export type TooltipFormatter = (point: Point) => { x: ReactNode; y: ReactNode };

export interface TooltipProps {
  series: Array<{
    serieId: string | number;
    color: string;
    point: Point;
  }>;
  formatter?: TooltipFormatter;
}

export const defaultFormatter: TooltipFormatter = ({ x, y }: Point) => ({ x: `${x}`, y: `${y}` });

export const Tooltip: React.FC<TooltipProps> = ({ series, formatter = defaultFormatter }) => {
  const formattedTotal = useMemo(() => {
    const unformattedTotal = series.reduce((total, { point: { y } }) => total + Number(y), 0);
    return formatter({ x: null, y: unformattedTotal }).y;
  }, [series, formatter]);

  return (
    <Card
      css={{
        p: '$space2',
        whiteSpace: 'nowrap',
      }}
    >
      {series.map(({ point, color, serieId }, index) => {
        const { x, y } = formatter(point);
        return (
          <div key={serieId}>
            {index === 0 && <Heading variant="sm">{x}</Heading>}
            <Box
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: '$space1',
              }}
            >
              <Box css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box
                  css={{
                    width: '$size3',
                    height: '$size3',
                    borderRadius: '$radius3',
                    background: color,
                    mr: '$space1',
                  }}
                ></Box>
                <Text variant="caption" color="subdued">
                  {serieId}
                </Text>
              </Box>
              <Text variant="caption" css={{ fontWeight: '$bold', ml: '$space2' }}>
                {y}
              </Text>
            </Box>
          </div>
        );
      })}
      {series.length > 1 && (
        <Box
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: '$space1',
          }}
        >
          <Text variant="caption" css={{ fontWeight: '$bold', ml: '$space4' }}>
            Total
          </Text>
          <Text variant="caption" css={{ fontWeight: '$bold', ml: '$space2' }}>
            {formattedTotal}
          </Text>
        </Box>
      )}
    </Card>
  );
};
