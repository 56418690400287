import { LegendProps as NivoLegendProps } from '@nivo/legends';
import {
  Line as NivoLine,
  Point as NivoPoint,
  LineProps as NivoLineProps,
  PointTooltip as NivoPointTooltip,
  Layer as NivoLayer,
} from '@nivo/line';
import { TooltipWrapper as NivoTooltipWrapper } from '@nivo/tooltip';
import useResizeObserver from '@react-hook/resize-observer';
import React, { useRef, useState } from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

import { Tooltip, TooltipFormatter } from './Tooltip';
import {
  AxisDataType,
  LegendProps,
  MouseHandler,
  Point,
  PointDataType,
  XAxisScale,
  YAxisScale,
} from './types';
import {
  DATE_DISPLAY_FORMAT,
  handleXAxisFormat,
  handleYAxisFormat,
  calculateMargin,
  calculateMaxYScale,
  calculateGridYValues,
  calculateMinYScale,
  calculateAnchor,
} from './utils';

interface LineChartProps {
  animate?: boolean;
  css?: PicnicCss;
  children: Array<React.ReactElement<LineProps>> | React.ReactElement<LineProps>;
  tooltipOverride?: NivoPointTooltip;
  tooltipFormatter?: TooltipFormatter;
  xAxisFormatter?: (val: PointDataType) => string;
  enablePoints?: boolean;
  colors?: string[];
  enableSlices?: NivoLineProps['enableSlices'];
  xScale: XAxisScale;
  yScale: YAxisScale;
  onMouseEnter?: MouseHandler;
  onMouseMove?: MouseHandler;
  onMouseLeave?: MouseHandler;
  onClick?: MouseHandler;
  isYAxisStartAtZero?: boolean;
  legend?: LegendProps;
  layers?: NivoLayer[];
}

const PU_POINT_SIZE = 8;
// taken from https://www.figma.com/file/egERPX1v5qMZ3GD1X75vM4/01-Analytics-%2F-Dashboard-Tab
// Corresponds with these Picnic tokens that are not parsed and cannot be used by name
// ['$bgDecorative4Accent', '$bgDecorative2Accent', '$bgWarningAccent', '$bgDecorative1Accent']
export const CHART_LINE_COLORS = ['#C878D1', '#82C8D2', '#FFA175', '#BDD185'];
const PU_TEXT_COLOR = '#8D8F91';
const PU_CROSSHAIR_COLOR = '#8D8F91';
const CHART_LINE_LAYERS: NivoLayer[] = [
  'grid',
  'markers',
  'axes',
  'areas',
  'crosshair',
  'lines',
  'slices',
  'points',
  'mesh',
  'legends',
];

interface LineProps {
  id: string;
  points: Point[];
}

// transform custom handler into internal nivo handler
const mouseEventTransform =
  (handler?: MouseHandler) => (point: NivoPoint, event: React.MouseEvent) => {
    if (handler) {
      handler({ serieId: `${point.serieId}`, point: { x: point.data.x, y: point.data.y } }, event);
    }
  };

const Line: React.FC<LineProps> = () => {
  throw new Error('Line must be used inside LineChart');
};

const LineChartBase: React.FC<LineChartProps> = ({
  animate = true,
  css,
  children,
  enablePoints = false,
  colors = CHART_LINE_COLORS,
  enableSlices,
  tooltipOverride,
  tooltipFormatter,
  xAxisFormatter,
  xScale,
  yScale,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  onClick,
  isYAxisStartAtZero = true,
  legend,
  layers = CHART_LINE_LAYERS,
}) => {
  const [responsiveWidth, setResponsiveWidth] = useState(100);
  const [responsiveHeight, setResponsiveHeight] = useState(100);
  const parentRef = useRef<HTMLDivElement>(null);

  useResizeObserver(parentRef, (entry) => {
    if (entry.target !== null) {
      const { clientWidth, clientHeight } = entry.target;
      setResponsiveWidth(clientWidth);
      setResponsiveHeight(clientHeight);
    }
  });

  const data = React.Children.map(children as Array<React.ReactElement<LineProps>>, (child) => {
    return { data: (child.props as LineProps).points, id: (child.props as LineProps).id };
  });

  const yValues = data.map((line) => line.data.map((point) => point.y as number));
  const maxYScale = calculateMaxYScale({
    yValues,
    numTicks: yScale.numTicks,
    isYAxisStartAtZero,
  });

  const minYScale = calculateMinYScale({
    yValues,
    isYAxisStartAtZero,
  });

  const gridYValues = calculateGridYValues({
    maxYScale,
    minYScale,
    numTicks: yScale.numTicks,
  });

  return (
    <Box ref={parentRef} css={css}>
      <NivoLine
        width={responsiveWidth}
        height={responsiveHeight}
        data={data}
        useMesh={true}
        animate={animate}
        margin={calculateMargin(gridYValues, !!legend)}
        enableGridX={false}
        enablePoints={enablePoints}
        enableSlices={enableSlices}
        pointSize={PU_POINT_SIZE}
        colors={colors}
        tooltip={(input) => {
          if (tooltipOverride) {
            return tooltipOverride(input);
          }

          const series = [
            {
              index: input.point.index,
              serieId: input.point.serieId,
              color: input.point.color,
              point: input.point.data,
            },
          ];

          const anchor = calculateAnchor({ data, series });
          const tooltipProps = {
            series,
            formatter: tooltipFormatter,
          };

          return (
            <NivoTooltipWrapper anchor={anchor} position={[0, 0]}>
              <Tooltip {...tooltipProps} />
            </NivoTooltipWrapper>
          );
        }}
        sliceTooltip={({ slice: { points } }) => {
          const series = points
            .map(({ data: point, index, serieId, serieColor }) => ({
              index,
              serieId,
              color: serieColor,
              point,
            }))
            // Reverse to match order displayed in MetricChartCard
            .reverse();

          const anchor = calculateAnchor({ data, series });
          const tooltipProps = {
            series,
            formatter: tooltipFormatter,
          };

          return (
            <NivoTooltipWrapper anchor={anchor} position={[0, 0]}>
              <Tooltip {...tooltipProps} />
            </NivoTooltipWrapper>
          );
        }}
        theme={{
          textColor: PU_TEXT_COLOR,
          fontFamily: 'Ginto Normal',
          crosshair: {
            line: {
              stroke: PU_CROSSHAIR_COLOR,
            },
          },
        }}
        yScale={{ ...yScale, max: maxYScale, min: minYScale }}
        xScale={{
          ...xScale,
          ...(xScale.type === AxisDataType.DATE
            ? { format: DATE_DISPLAY_FORMAT, useUTC: false }
            : {}),
        }}
        axisLeft={{
          tickValues: gridYValues,
          format: handleYAxisFormat,
        }}
        axisBottom={{
          tickValues:
            xScale.tickValues ||
            (xScale.numTicks &&
              Math.min(xScale.numTicks, Math.max(...data.map((series) => series.data.length - 1)))),
          format:
            xAxisFormatter ||
            handleXAxisFormat(xScale.type === AxisDataType.DATE ? xScale.precision : undefined),
        }}
        gridYValues={gridYValues}
        onMouseEnter={mouseEventTransform(onMouseEnter)}
        onMouseLeave={mouseEventTransform(onMouseLeave)}
        onMouseMove={mouseEventTransform(onMouseMove)}
        onClick={mouseEventTransform(onClick)}
        legends={legend ? [legend as NivoLegendProps] : []}
        layers={layers}
      />
    </Box>
  );
};

type ComponentType = typeof LineChartBase;
interface CompositeComponent extends ComponentType {
  Line: typeof Line;
}

const LineChart = LineChartBase as CompositeComponent;

LineChart.Line = Line;

export { LineChart };
